define("discourse/plugins/discourse-templates/discourse/connectors/editor-preview/d-templates", ["exports", "@ember/object", "discourse/lib/get-owner"], function (_exports, _object, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SELECTOR_EDITOR_PREVIEW = "#reply-control .d-editor-preview-wrapper > .d-editor-preview";
  var _default = _exports.default = dt7948.p({
    setupComponent(args, component) {
      component.setProperties({
        templatesVisible: false,
        model: (0, _getOwner.getOwnerWithFallback)(this).lookup("service:composer").model
      });
      this.appEvents.on("discourse-templates:show", this, "show");
      this.appEvents.on("discourse-templates:hide", this, "hide");
    },
    teardownComponent() {
      this.appEvents.off("discourse-templates:show", this, "show");
      this.appEvents.off("discourse-templates:hide", this, "hide");
    },
    shouldRender(args, component) {
      return !component.site.mobileView;
    },
    show(_ref) {
      let {
        onInsertTemplate
      } = _ref;
      const elemEditorPreview = document.querySelector(SELECTOR_EDITOR_PREVIEW);
      if (elemEditorPreview) {
        elemEditorPreview.style.display = "none";
      }
      this.set("onInsertTemplate", onInsertTemplate);
      this.set("templatesVisible", true);
    },
    hide() {
      const elemEditorPreview = document.querySelector(SELECTOR_EDITOR_PREVIEW);
      if (elemEditorPreview) {
        elemEditorPreview.style.display = "";
      }
      this.set("templatesVisible", false);
    }
  }, [["method", "show", [_object.action]], ["method", "hide", [_object.action]]]);
});